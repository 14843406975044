
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { createApolloClient } from "lib/apollo/apollo";
import getEntry from "lib/apollo/queries/entry";
import getGlobals from "lib/apollo/queries/globals";
import Layout from "components/Layout";
import DynamicContent from "components/DynamicContent";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { setCurrentPageBreadcrumbs } from "../store/slices/pageSlice";
import { searchJobs } from "../store/slices/searchSlice";
import fetchSlugs from "../lib/apollo/queries/slugs";
function CatchAllPage({ content = {}, globals = {} }) {
    const localization = {
        all: globals?.localization,
        page: {
            locale: content?.locale,
            url: content?.url,
            locale_variants: content?.locale_variants
                // filter out all variants that are not published
                ?.filter((variant) => variant.status === "published")
                // remove property "status" from each variant completely
                ?.map((variant) => ({
                locale_name: variant.site.name,
                locale: variant.locale,
                url: variant.url
            }))
        }
    };
    const dispatch = useDispatch();
    useEffect(() => {
        let parent = content?.parent;
        const parents = [];
        while (parent) {
            parents.push({ url: parent?.url, display: parent?.title });
            parent = parent?.parent;
        }
        dispatch(setCurrentPageBreadcrumbs([
            ...parents.reverse(),
            { url: null, display: content?.title },
        ]));
        dispatch(searchJobs({}));
    }, [content]);
    return (<Layout globals={globals} content={content} localization={localization}>
      <DynamicContent content={content}/>
    </Layout>);
}
const getStaticProps = async ({ locale, params = null, previewData = null, }) => {
    const apollo = createApolloClient(previewData?.token ?? null);
    if (params?.slug?.[0] === "job-detail") {
        return {
            redirect: {
                destination: "/jobs",
                permanent: false
            }
        };
    }
    const [content, globals] = await Promise.all([
        getEntry(apollo, "pages", params?.slug || "home", locale, previewData?.token || false),
        getGlobals(apollo, locale),
    ]);
    if (!content || !globals) {
        return {
            notFound: true
        };
    }
    return {
        props: {
            content,
            globals
        }
    };
};
export const getStaticPaths = async () => {
    let paths = await fetchSlugs("pages");
    paths = paths.filter((path) => path.params.slug.length);
    // console.log(paths)
    return {
        paths,
        fallback: false
    };
};
export default CatchAllPage;

    async function __Next_Translate__getStaticProps__192f7934ec0__(ctx) {
      const res = await getStaticProps(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/[...slug]',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__192f7934ec0__ as getStaticProps }
  